import React from 'react';
import styled from 'styled-components';
import ContactButton from './ContactButton';

const StepWrapper = styled.div`
  width: fit-content;
  display: flex;
  column-gap: 2.6vw;
  position: relative;
  z-index: 3;

  @media screen and (max-width: 1440px) {
    column-gap: 37px;
  }

  @media screen and (max-width: 768px) {
    width: calc(100vw - 32px);
    flex-direction: column-reverse;
    z-index: 0;
  }
`;

const StepsLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 35.14vw;

  @media screen and (max-width: 1440px) {
    height: 463px;
  }
`;

const Marker = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 20px;
  background-color: rgba(162, 107, 227, 0.2);

  &:after {
    content: "";
    display: flex;
    margin: 4px;
    width: 9px;
    height: 9px;
    border: 1px solid #A26BE3;
    border-radius: 20px;
  }
`;

const Line = styled.div`
  width: 3px;
  height: 35.14vw;
  background-color: rgba(162, 107, 227, 0.2);

  @media screen and (max-width: 1440px) {
    height: 463px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 32.29vw;
  height: max-content;
  row-gap: 1.41vw;
  text-align: left;
  margin-top: -0.5vw;
  z-index: 3;

  @media screen and (max-width: 1440px) {
    width: 465px;
    max-width: 35vw;
    row-gap: 20px;
    margin-top: -8px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    row-gap: 14px;
    margin-top: 0;
    max-width: 100%;
  }
`;

const Title = styled.h3`
  font-size: 2.08vw;
  line-height: 2.55vw;
  font-weight: 800;
  letter-spacing: -0.032em;
  color: rgba(255, 255, 255, 1);
  margin: 0;

  @media screen and (max-width: 1440px) {
    font-size: 32px;
    line-height: 39px;
  }
`;

const Text = styled.p`
  font-size: 1.25vw;
  line-height: 1.51vw;
  font-weight: 400;
  letter-spacing: -0.0108em;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 0.83vw 0;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 12px 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 19.5px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 2;
  margin-left: ${props => props.step.left ? props.step.left : '0'};
  left: 50%;
  transform: translateX(-50%);
  height: ${props => props.step.width ? props.step.width : '500px'};
  width: ${props => props.step.width ? props.step.width : '500px'};
  max-width: ${props => props.step.maxWidth ? props.step.maxWidth : '100vw'};
  max-height: ${props => props.step.maxWidth ? props.step.maxWidth : '100vw'};
`;

const StepImage = styled.img`
  display: flex;
  position: relative;
  top: ${props => props.step.top ? props.step.top : '0'};
  width: 100%;
  height: auto;
  z-index: 2;
`;

const BrandsSteps = ({ step, index, length, isMobile}) => {
  return (
    <StepWrapper> 
      {!isMobile &&
        <StepsLine>
          <Marker> </Marker>
          {index < (length - 1) && <Line> </Line>}        
        </StepsLine>
      } 
      <TextWrapper id={"text" + index} style={isMobile ? { marginTop: step.mobile.bottom } : {}}>
        <Title>
          {step.title}
        </Title>
        <Text>
          {step.text}
        </Text>  
        <ContactButton width={293} height={50} link="https://lp.turbopartners.com.br/commerce/lp4-2/?utm_campaing=home" target="_blank" text={step.button} textColor='rgba(162, 107, 227, 1)' background='rgba(162, 107, 227, 1)' innerBackground='rgba(162, 107, 227, 0.1)' shadow='none'/>
      </TextWrapper>  
      {isMobile &&
        <ImageWrapper step={step.mobile}>
          <StepImage src={step.gif} step={step.mobile}/>
        </ImageWrapper>
      }   
    </StepWrapper>
  );
};

export default BrandsSteps;
