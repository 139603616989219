import React from 'react';
import styled, { keyframes } from 'styled-components';
import CommentsCard from '../components/CommentsCard';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import Icon from '../components/Icon';
import ContactButton from '../components/ContactButton';

const floatation = keyframes`
	0%, 100% { transform: translateY(min(-2vw, -25px)); }
  50%   { transform: translateY(max(2vw, 25px)); }
`

const gradientAnimation = keyframes`
	0%, 100% { background-position: 0 50%; }
  50%   { background-position: 100% 50%; }
`

const CommentsWrapper = styled.div`
  width: 100vw;
  padding: 12.5vw 0 5.2vw 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  .swiper {
    overflow-x: hidden;
  }

  .swiper-wrapper {
    display: flex;
    height: 100%;
    width: calc(100% + 30px);
  }

  @media screen and (max-width: 1440px) {
    padding: 180px 0 75px 0;
  }

  @media screen and (max-width: 900px) and (min-width: 769px) {
    .swiper-wrapper {
      width: calc(100% + 16px);
    }
  }

  @media screen and (max-width: 768px) {
    padding: 67px 0 150px 0;

    .swiper-wrapper {
      width: max-content;
    }
  }
`;

const GreenElipse = styled.div`
  width: 23.5vw;
  height: 23.5vw;
  position: absolute;
  top: 11.7vw;
  right: 0;
  animation: ${floatation} 12s ease-in-out infinite;
  z-index: 0;
  background: linear-gradient(180deg, #00DD87 0%, rgba(162, 107, 227, 0) 100%);
  filter: blur(30px);
  border-radius: 100%;

  @media screen and (max-width: 1440px) {
    width: 338px;
    height: 338px;
  }

  @media screen and (max-width: 768px) {
    width: 238px;
    top: 55px;
    right: -96px;
    animation: ${floatation} 6s ease-in-out infinite;
  }
`;

const GradientElipse = styled.div`
  width: 26.9vw;
  height: 26.9vw;
  position: absolute;
  bottom: 7.5vw;
  left: -9.7vw;
  animation: ${floatation} 12s ease-in-out infinite;
  animation-delay: -6s;
  z-index: 0;
  background: radial-gradient(87.84% 87.84% at 59.08% 12.16%, #0E11DB 19.17%, #DA41A5 63.97%, #EC894B 100%);
  filter: blur(35px);
  border-radius: 100%;

  @media screen and (max-width: 1440px) {
    width: 387px;
    height: 387px;
    bottom: 108px;
    left: -140px;
  }

  @media screen and (max-width: 768px) {
    width: 517px;
    bottom: 551px;
    left: -269px;
    animation: ${floatation} 6s ease-in-out infinite;
  }
`;

const CommentsColumn = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const Title = styled.div`
  font-size: 4.48vw;
  line-height: 6vw;
  font-weight: 700;
  margin: 0 0 50px 0;
  color: #F0F0F0;
  letter-spacing: -0.0466em;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1440px) {
    font-size: 64px;
    line-height: 86px;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 39px;
    row-gap: 10px;
  }
`;

const FirstRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  column-gap: 32px;
  z-index: 1;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 49px;
    margin: 0 0 10px 0;
    letter-spacing: -0.0466em;
    align-items: end;
    column-gap: 14px;
  }
`;

const GradientTitle = styled.div`
  background: linear-gradient(83.66deg, #0E11DB 6.52%, #DA41A5 59.62%, #EC894B 102.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 400;
  z-index: 1;
  
  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
`;

const ContactWrapper = styled.div`
  align-self: center;
  padding: 2px;
  margin-top: 100px;
  box-sizing: border-box;
  width: 1165px;
  max-width: 100%;
  background: linear-gradient(160deg, #0E11DB, #0E11DB, #DA41A5, #DA41A5, #EC894B, #EC894B);
  background-size: 200% 200%;
  border-radius: 8px;
  animation: ${gradientAnimation} 4s ease-in-out infinite;

  @media screen and (max-width: 1440px) {
    width: 876px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 80px 0 0 0;
  }
`;

const ContactInnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #08031A;
  box-sizing: border-box;
  padding: 0 40px;
  height: 84px;
  width: 100%;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  letter-spacing: -0.032em;
  border-radius: inherit;

  @media screen and (max-width: 1440px) {
    font-size: 18px;
    line-height: 22px;
    padding: 0 30px;
  }

  @media screen and (max-width: 768px) {
    height: max-content;
    padding: 24px 14px;
    flex-direction: column;
    row-gap: 12px;
    font-size: 20px;
    line-height: 24px;
  }
`;

const Comments = ({ isMobile, isTablet }) => {
  const clientsInfo = [
    {
      text: 'Desde o início da parceria, o pessoal da Turbo sempre foi muito atencioso. Além disso, o know-how deles no digital foi fundamental para que escalássemos a nossa operação até múltiplos milhões mensais em faturamento.',
      photo: 'client-1_photo.png',
      name: 'Victor Peixoto',
      role: 'CEO da GetPower Solar'
    },
    {
      text: 'Eu não sabia que a minha empresa precisava tanto da Turbo até conhecer eles, excelência no atendimento e nos resultados que são demonstrados dia após dia! A Byr está em constante crescimento e a Turbo faz parte disso, que seja apenas o começo de uma longa trajetória. Só tenho agradecimentos à toda equipe!!',
      photo: 'client-2_photo.png',
      name: 'Raissa de Medeiros',
      role: 'CEO da Loja Byr'
    },
    {
      text: 'Excelente atendimento. Toda a equipe é muito atenciosa, competente e se coloca à disposição sempre que necessário. Obrigado pela parceria!',
      photo: 'client-3_photo.png',
      name: 'Felipe',
      role: 'CEO da Nina Saúde'
    }
  ];

  return (
    <CommentsWrapper className="page-width">
      <GreenElipse/>
      <GradientElipse/>
      <div>
        <Title>
          <FirstRow>
            <span style={{ maxWidth: isMobile ? 'calc(100% - 50px)' : '90%' }}>O que move o nosso foguete?</span>
            <Icon margin={isMobile ? '0 0 6px' : '0'} height={isMobile ? 40 : 67} icon='rocket'/>
          </FirstRow>
          <GradientTitle>
            Os desafios dessa galera:
          </GradientTitle>
        </Title>
        {isMobile ? (
          <CommentsColumn>
            {clientsInfo.map((client, index) => (
              <CommentsCard client={client} key={index}/>
            ))}
          </CommentsColumn>
        ) : (
          <Swiper
            modules={[Pagination]}
            spaceBetween={isTablet ? 16 : 30}
            slidesPerView={"auto"}
            pagination={{ clickable: true }}
            allowTouchMove={window.innerWidth <= 900}
          >
            {clientsInfo.map((client, index) => (
              <SwiperSlide key={index}>
                <CommentsCard client={client} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <ContactWrapper>
        <ContactInnerWrapper>
          <span style={{ zIndex: 3 }}>Pronto pra turbinar seu negócio? Marque uma call com a gente!</span>
          <ContactButton
            width={219} height={50} link="https://lp.turbopartners.com.br/commerce/lp4-2/?utm_campaing=home" target="_blank" text='Entrar em Contato' fontSize={16} fontWeight={700} solidButton={true} background='linear-gradient(84.5deg, #0E11DB 2.04%, #DA41A5 76.86%, #EC894B 97.89%)' shadow='0px 6px 16px rgba(0, 0, 0, 0.38)'
          />
        </ContactInnerWrapper>
      </ContactWrapper>  
    </CommentsWrapper>
  );
};

export default Comments;
