import React from 'react';
import styled from 'styled-components';
import MenuItem from '../components/MenuItem';
import { CgMenuRight } from 'react-icons/cg';
import { MdClose } from 'react-icons/md';
import Drawer from '@mui/material/Drawer';
import { List } from '@mui/material'

const MenuList = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 60px;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 1165px) {
    gap: 30px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const HeaderWrapper = styled.div`
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(8, 5, 18, 0.8);
`;

const NavBar = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;

  @media screen and (max-width: 1165px) {
    gap: 30px;
  }
`;

const GradientButton = styled.a`
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.04vw;
  font-weight: 600;
  color: #fefefe;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #4E62EE;
  background: unset;
  width: max-content;
  height: 50px;
  padding: 0 24px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s linear;

  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    margin: 30px 0 0 16px;
    padding: 0 16px; 
  }

  &:hover {
    background: #4E62EE;
  }
`;

const Logo = styled.img`
  height: 33px;
  width: auto;
`;

const Menu = ({ items, click, dropdown, setDropdown }) => {
  
  return (
    <MenuList>
      {items.map((menu, index) => {
        return <MenuItem item={menu} index={index} key={index} click={click} dropdown={dropdown} setDropdown={setDropdown}/>
      })}
    </MenuList>
  );
};

const Header = ({ isTablet }) => {
  const [open, setOpen] = React.useState(false);
  const [inactive, setInactive] = React.useState(false);
  const [dropdown, setDropdown] = React.useState(-1);
  
  const menuItems = [
    {
      title: 'Soluções',
      submenu: [
        {
          title: 'Commerce',
          text: 'Acelere suas vendas através de estratégias de marketing customizadas para seu negócio',
          icon: '/TurboCommerce_2.svg',
          url: '/commerce',
          targetOut: false
        },
        {
          title: 'Dashboard',
          text: 'Veja todos os dados relevantes do seu e-commerce em um só lugar',
          icon: '/TurboDashboard_2.svg',
          url: '/dash',
          targetOut: false
        },
        {
          title: 'Tech',
          text: 'Sites, E-commerces e Landing Pages de alta performance para turbinar o seu negócio.',
          icon: '/TurboTech_2.svg',
          url: '/tech',
          targetOut: false
        },
        {
          title: 'Creators',
          text: 'Faça seu CPA despencar com anúncios sem cara de anúncios.',
          icon: '/TurboCreators_2.svg',
          url: '/creators',
          targetOut: false
        },
        {
          title: 'Class',
          text: 'A escola de negócios e marketing para quem deseja viver do mercado digital',
          icon: '/TurboClass_2.svg',
          url: 'https://lp.turbopartners.com.br/class/cv2',
          targetOut: true
        },
        {
          title: 'Ventures',
          text: 'Se você tem um negócio fora de série, queremos ser seus sócios.',
          icon: '/TurboVentures_2.svg',
          targetOut: false
        },
        // {
        //   title: 'Fulfillment',
        //   color: '#B25956',
        //   icon: '/menu_fulfillment.svg',
        // },
        {
          title: 'Bank',
          text: 'Crédito descomplicado para destravar o crescimento do seu e-commerce',
          icon: '/TurboBank_2.svg',
          targetOut: false
        }
      ],
      url: ''
    },
    {
      title: 'Materiais',
      submenu: [
        {
          title: 'Receba ebook digital repleto de conhecimento',
          text: 'O Facebook é uma das plataformas mais populares do mundo e oferece aos anunciantes.',
          icon: '/ebook-image.png',
          url: '',
          targetOut: false,
          button: 'Quero receber',
          card: true
        },
        {
          title: 'Blog',
          text: 'Conteúdos relevantes sobre negócios e marketing digital.',
          icon: '/TurboBlog.svg',
          url: 'https://www.blog.turbopartners.com.br',
          targetOut: true
        },
        {
          title: 'Ebooks',
          text: 'Guias e materiais práticos para você implementar ainda hoje.',
          icon: '/TurboEbooks.svg',
          url: 'https://blog.turbopartners.com.br/ebooks/',
          targetOut: true
        },
        {
          title: 'Newsletter',
          text: 'Receba novidades e conteúdos em primeira mão no seu e-mail.',
          icon: '/TurboNewsletter.svg',
          url: 'https://blog.turbopartners.com.br/newsletter/',
          targetOut: true
        },
        {
          title: 'Cases',
          text: 'Sua marca pode ser a próxima a conquistar resultados como esses.',
          icon: '/TurboCases.svg',
          url: 'https://blog.turbopartners.com.br/cases-studies/',
          targetOut: true
        }
      ],
      url: ''
    },
    {
      title: 'Ver Mais',
      submenu: [
        {
          title: 'Indique e ganhe',
          text: 'Indique a Turbo e receba R$300 por contrato assinado.',
          icon: '/TurboReferral.svg',
          url: 'https://lp.turbopartners.com.br/commerce/indique-e-ganhe',
          targetOut: true
        },
        {
          title: 'Trabalhe Conosco',
          text: 'Estamos sempre a procura de boas pessoas. Se você é uma, clique aqui.',
          icon: '/TurboWork.svg',
          url: '/talento',
          targetOut: false
        },
        {
          title: 'Fale conosco',
          text: 'Clique aqui e fale agora a com nossa equipe.',
          icon: '/TurboWpp.svg',
          url: 'https://api.whatsapp.com/send?phone=5527998558170&text=Ol%C3%A1,%20vim%20pelo%20site.%20Pode%20me%20ajudar?',
          targetOut: true
        }
      ],
      url: ''
    }
  ];

  function collectUTM() {
    const { href } = window.location;
    href.split('#');
    const queryString = (href.includes('?')) ? href.slice(href.indexOf('?')) : '';
    
    if (queryString !== '') {
      const changeLinks = document.querySelectorAll('.MuiDrawer-modal .change-link');
      console.log(changeLinks);
      changeLinks.forEach(link => {
        console.log(link.href);
        if (!link.href.includes('#packages') && !link.href.includes('#redirect') && !link.href.includes('wa.me')) {
          const { href } = link;
          link.href = `${href}${queryString}`;
        }
      });
    }
  };

  React.useEffect(() => {
    if(open) {
      setTimeout(() => collectUTM(), 1000);
    }
  }, [open]);

  return (
    <HeaderWrapper className="page-width" style={ inactive ? { background: 'unset', zIndex: 10000 } : { backdropFilter: 'blur(25.5px)', WebkitBackdropFilter: 'blur(25.5px)' }}>
      <a href="/" className='change-link' style={{ zIndex: 100 }}>
        <Logo src={'/TurboPartners_white.svg'}/>
      </a>
      {!isTablet && 
        <NavBar>
          <Menu items={menuItems} click={() => setInactive(!inactive)} dropdown={dropdown} setDropdown={(e) => setDropdown(e)}/>
          <GradientButton className='change-link' href='https://lp.turbopartners.com.br/commerce/lp4-2/?utm_campaing=home' target='_blank'>
            Turbinar agora
          </GradientButton>
        </NavBar>
      }
      {isTablet &&
        <div>
          {open ? (
            <MdClose size="39px" color={'#FEFEFE'} onClick={() => {setOpen(!open); setInactive(!inactive)}}/>
          ) : (
            <CgMenuRight size="39px" color={'#FEFEFE'} onClick={() => {setOpen(!open); setInactive(!inactive)}}/>
          )}     
          <Drawer
            anchor={'right'}
            elevation={0}
            open={open}
            onClose={() => setOpen(false)}
            hideBackdrop={true}
            PaperProps={{
              sx: {
                backgroundColor: "transparent"
              }
            }}
          >
            <List style={{ padding: '104px 0 40px', height: 'fit-content', minHeight: dropdown === -1 && '100vh', width: '100vw', background: 'rgba(8, 5, 18, 0.8)', backdropFilter: 'blur(25.5px)', WebkitBackdropFilter: 'blur(25.5px)' }}>
              <Menu items={menuItems} dropdown={dropdown} setDropdown={(e) => setDropdown(e)}/>
              <GradientButton className='change-link' href='https://lp.turbopartners.com.br/commerce/lp4-2/?utm_campaing=home' target='_blank'>
                Turbinar agora
              </GradientButton>
            </List>
          </Drawer>
        </div>
      }
    </HeaderWrapper>
  );
};


export default Header;
